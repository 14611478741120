import { HttpClient, HttpParams } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "src/app/services/api-service.service";
import { AuthService } from "src/app/services/auth.service";
import { ConfigService } from "src/app/services/config.service";
import { SharedService } from "src/app/services/shared.service";
// import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import { ToastService } from 'src/app/services/toast.service';
import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: "app-about-us",
  templateUrl: "./about-us.component.html",
})
export class AboutUsComponent extends ApiService  implements OnInit {
  @ViewChild('form', { static: true }) form;

  public Editor = ClassicEditor;
  public EditorAr = ClassicEditor;
  public data = {
    description: '',
    description_ar: ''

};
public authorization = localStorage.getItem('access_token');

public configuration: any;
public configurationAr: any;
  constructor(
    private api: ApiService,
    private router: Router,
    public http: HttpClient,
    public shared : SharedService,
    public config: ConfigService,
    public authService: AuthService,
    public toastr: ToastService
  ) {
    super(http, config);
   }
  
ngOnInit(): void {
    this.list();

this.configuration = {
  image: {
  },
  ckfinder: {
    withCredentials: true,
    uploadUrl: this.config.apiURL + 'aboutus/upload',
    headers: {
     'Authorization': "Bearer " +this.authorization
    }
  } 
};
this.configurationAr = {
  language: 'ar' ,
  image: {
  },
  ckfinder: {
    withCredentials: true,
    uploadUrl: this.config.apiURL + 'aboutus/upload',
    headers: {
     'Authorization': "Bearer " +this.authorization
    }
  } 
};

  }
  list() {
    const api = "aboutus/list";
    let params = new HttpParams();
    this.getData(api, params, (result) => {
      if(result.data!=null) 
       setTimeout(() => {
          this.form.setValue({
            description: result.data.description,
            description_ar: result.data.description_ar,
          });
        },
          300);
    });
  }

        //  setTimeout(() => {
        //   this.form.setValue({
        //     name: data.name,
        //     name_ar: data.name_ar,
        //     description: data.description,
        //     description_ar: data.description_ar,
        //     image: '',
        //     link: '',
        //   });
  
        // },
        //   700);
 
  
  // }
  
 
   onSubmit(form){
     
    if (form.valid ) {

      const api = "aboutus/update";
       this.postData(api, form.value, (result) => {
        if (result.success == 1) {
          this.toastr.success(result.message);
          // this.router.navigateByUrl('/admin/about-us');
        }
      });
    }   }
    onReady(eventData) {
      eventData.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return new UploadAdapter(loader,this.config);
      };
    }
     
}

export class UploadAdapter {
  private config;

  private loader;
  xhr: any;
  constructor(loader: any,config: any) {
    this.loader = loader;
    this.config = config;
  }

  upload() {
    return this.loader.file
      .then(file => new Promise((resolve, reject) => {
        this._initRequest();
        this._initListeners(resolve, reject, file);
        this._sendRequest(file);
      }));
  }

  // Aborts the upload process.
  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  _initRequest() {
    const xhr = this.xhr = new XMLHttpRequest();
    xhr.open('POST', this.config.apiURL + 'aboutus/upload', true);
    xhr.responseType = 'json';
    
    xhr.setRequestHeader('Authorization','Bearer ' + localStorage.getItem('access_token')); // set your token here
  }

  // Initializes XMLHttpRequest listeners.
  _initListeners(resolve, reject, file) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = `Couldn't upload file: ${file.name}.`;

    xhr.addEventListener('error', () => reject(genericErrorText));
    xhr.addEventListener('abort', () => reject());
    xhr.addEventListener('load', () => {
      const response = xhr.response;

      if (!response || response.error) {
        return reject(response && response.error ? response.error.message : genericErrorText);
      }

      resolve({
        default: response.data.imageURL
      });
    });

    if (xhr.upload) {
      xhr.upload.addEventListener('progress', evt => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  // Prepares the data and sends the request.
  _sendRequest(file) {
    // Prepare the form data.
    const data = new FormData();

    data.append('body', file);
    this.xhr.send(data);
  }

}