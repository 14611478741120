import { Component, OnInit } from "@angular/core";
import { SharedService } from "src/app/services/shared.service";

@Component({
  selector: "app-header-stats",
  templateUrl: "./header-stats.component.html",
})
export class HeaderStatsComponent implements OnInit {
  constructor(
    public shared:SharedService
  ) {}

  ngOnInit(): void {}
}
