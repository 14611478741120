import { HttpClient, HttpParams } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "src/app/services/api-service.service";
import { AuthService } from "src/app/services/auth.service";
import { ConfigService } from "src/app/services/config.service";
import { SharedService } from "src/app/services/shared.service";


@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
})
export class DashboardComponent extends ApiService implements OnInit {
  constructor(
    private api: ApiService,
    private router: Router,
    public http: HttpClient,
    public shared: SharedService,
    public config: ConfigService,
    public authService: AuthService,
  ) {
    super(http, config);
  }
  page = 1;
  search = '';
  data = [];

  ngOnInit(): void {
    this.list(this.page);
  }
  
  list(page) {
    const api = "clients";
    let params = new HttpParams();
    params = params.append("page", page == 0 || page == 'NaN' ? 1 : page.toString());
    params = params.append("search", this.search.toString());
    this.getData(api, params, (result) => {
      this.data = result.data;
    });
  }

}
