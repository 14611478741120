import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  production = 'https://backend.green-line.app';
  local = 'http://localhost:8000';
  baseUrl = this.production;
  apiURL = `${this.baseUrl}/api/`;
  filesUrl = `${this.baseUrl}/storage/`;

  constructor() { }
}
