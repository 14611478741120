import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api-service.service';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigService } from 'src/app/services/config.service';
import { ToastService } from 'src/app/services/toast.service';
declare const google: any;

@Component({
  selector: 'app-route-details',
  templateUrl: './route-details.component.html',
  // styleUrls: ['./route-details.component.css']
})
export class RouteDetailsComponent extends ApiService implements OnInit {
  @ViewChild('map', { static: true }) mapElement: ElementRef;
  @ViewChild('form', { static: true }) form;
 

  constructor(
    private router: Router,
    public http: HttpClient,
    public config: ConfigService,
    public authService: AuthService,
    public route: ActivatedRoute,
    public toastr: ToastService,
    public dom: DomSanitizer
  ) {
    super(http, config);
    console.log(this.route)
  }

  pageName = 'Add Route Details';
  labels = "AB";
  labelIndex = 0;
  map: any;
  lat = '33.889662';
  long = '35.494855';
  directionsService = new google.maps.DirectionsService();
  markers = [];
  markersOld = [];
  routeDetails: { [key: string]: any };
  ngOnInit(): void {

    this.route.params.subscribe((data) => {
      console.log(data);

      data = JSON.parse(data.data);
      if (data.routes_details.length > 0) {
        this.getMarkers(data, 1);

        setTimeout(() => {
          this.form.setValue({
            address: data.routes_details[0].address,
            address_ar: data.routes_details[0].address_ar,
            // description: data.description,
            // description_ar: data.description_ar,
            audio: '',
          });
          this.audio = this.config.filesUrl + data.routes_details[0].audio;
          let latLng1 = { latLng: new google.maps.LatLng(data.routes_details[0].start_latitude, data.routes_details[0].start_longitude) }
          let latLng2 = { latLng: new google.maps.LatLng(data.routes_details[0].end_latitude, data.routes_details[0].end_longitude) }
          this.markersOld.push(latLng1);
          this.markersOld.push(latLng2);

          //  this.markers.push( {lat:data.routes_details[0].end_latitude},{lng: data.routes_details[0].end_longitude})
        },
          700)
        this.routeDetails = data.routes_details[0]
        this.api = "routeDetails/update/" + data.routes_details[0].id;
      } else {
        this.api = "routeDetails/create/" + data.id;

      }
      setTimeout(() => {
        this.initMap();
      }, 1000)
    })

  }



  audio: any;
  fileToUpload;
  // public data: { [k: string]: any } = {};
  api;

  onSubmit(form) {
    if (form.valid ) {

      form.value.start_latitude = this.markers[0].position.lat();
      form.value.start_longitude = this.markers[0].position.lng();
      form.value.end_latitude = this.markers[1].position.lat();
      form.value.end_longitude = this.markers[1].position.lng();

      form.value.audio = this.fileToUpload ?? this.audio;

      const formData = new FormData();

      Object.entries(form.value).forEach(
        ([key, value]) => {
          let x: any = value;
          formData.append(key, x);
        }
      );

      this.postData(this.api, formData, (result) => {
        if (result.success == 1) {
          this.toastr.success(result.message);
          this.router.navigateByUrl('/admin/routes');
        }
        else {
          this.toastr.success(result.message);

        }
      });
    }
  }

  onFileChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);


      this.fileToUpload = event.target.files[0];

      reader.onload = () => {

        this.audio = this.dom.bypassSecurityTrustResourceUrl(URL.createObjectURL(event.target.files[0]));

      };

    }
    else {
      this.fileToUpload = null;
      this.audio = ''
    }
  }

  initMap() {

    const myLatlng = new google.maps.LatLng(this.lat, this.long);
    const mapOptions = {
      zoom: 15,
      scrollwheel: false,
      center: myLatlng,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
           styles: [
        {
          featureType: "administrative",
          elementType: "labels.text.fill",
          stylers: [{ color: "#444444" }],
        },
        
        {
          featureType: "poi",
          elementType: "all",
          stylers: [{ visibility: "off" }],
        },
     
        {
          featureType: "road.highway",
          elementType: "all",
          stylers: [{ visibility: "simplified" }],
        },
        {
          featureType: "road.arterial",
          elementType: "labels.icon",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "transit",
          elementType: "all",
          stylers: [{ visibility: "off" }],
        },
      
      ]
    };

    this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);

    console.log(this.markers)

    if (this.markersOld.length == 2) {

      this.markersOld.forEach(element => {
        this.addMarker(element)
      });
      // 

    }

    this.map.addListener("click", (e) => {
      if (this.markers.length < 2) {

        this.addMarker(e)
      }

    });

  }

  addMarker(m) {
    const marker = new google.maps.Marker({
      position: m.latLng,
      map: this.map,
      animation: google.maps.Animation.DROP,
      label: this.labels[this.labelIndex++ % this.labels.length],

    });

    marker.addListener("click", (e) => {

      for (var i = 0; i < this.markers.length; i++) {
        if (this.markers[i].position == e.latLng) {
          if (this.markers[i].label == 'A')
            this.labelIndex >= 1 ? this.labelIndex = 0 : this.labelIndex = 1
          else (this.markers[i].label == 'B')
          this.labelIndex >= 1 ? this.labelIndex = 1 : this.labelIndex = 0

          this.markers[i].setMap(null);
          this.markers.splice(i, 1);
          console.log(this.markers)

          return;
        }
      }

    });

    this.markers.push(marker);
    if (this.markers.length == 2) {

      this.calcRoute(this.markers, this.map)
    }
  }

  calcRoute(markers, map) {
    let directionsDisplay = null
    directionsDisplay = new google.maps.DirectionsRenderer();
    if (directionsDisplay != null) {
      directionsDisplay.setMap(null);
    }

    var start = new google.maps.LatLng(markers[0].position.lat(), markers[0].position.lng());
    var end = new google.maps.LatLng(markers[1].position.lat(), markers[1].position.lng());
    var request = {
      origin: start,
      destination: end,
      travelMode: google.maps.TravelMode.WALKING
    };
    this.directionsService.route(request, function (response, status) {
      if (status == google.maps.DirectionsStatus.OK) {
        // console.log(response)
        directionsDisplay.setDirections(response);
        directionsDisplay.setMap(map);
        directionsDisplay.setOptions({ suppressMarkers: true });
      } else {
        alert("Directions Request from " + start.toUrlValue(6) + " to " + end.toUrlValue(6) + " failed: " + status);
      }
    });
  }

  page = 1;
  search = '';
  data :any;
  getMarkers(data, page) {
    // listMarkers
    const api = "routeDetails/listMarkers/" + data.routes_details[0].id;
    let params = new HttpParams();
    params = params.append("page", page == 0 || page == 'NaN' ? 1 : page.toString());
    params = params.append("search", this.search.toString());
    this.getData(api, params, (result) => {
      this.data = result.data;
    });
  }
  showModal = false;
  toggleModal() {
    this.showModal = !this.showModal;
  }




  markerDetails() {
    this.router.navigate(['/admin/routes/route-marker-add', { data: JSON.stringify(this.routeDetails) }] );

  }
  editMarkerDetails(marker) {
    this.router.navigate(['/admin/routes/route-marker-edit', { data: JSON.stringify(this.routeDetails) , marker : JSON.stringify(marker)}] );

  }
  delete(id) {

    let t = this.toastr.warning('Click here if you want to delete', (result) => {
      if (result) {
        const api = "routeDetails/deleteMarker/" + id;
        let params = new HttpParams();
        this.postData(api, params, (result) => {
          if (result.success == 1) {
            // this.list(1)
            // this.data.indexOf()
            let markers : any =this.data.data;
            markers.forEach((element,index)=>{
              if(element.id==id)  markers.splice(index,1);
           });
            this.toastr.success(result.message);
          }
        });
      }
    });


  }
}
