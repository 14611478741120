import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HttpClientModule } from '@angular/common/http';

// layouts
import { AdminComponent } from "./layouts/admin/admin.component";
import { AuthComponent } from "./layouts/auth/auth.component";

// admin views
import { DashboardComponent } from "./views/admin/dashboard/dashboard.component";
import { MapsComponent } from "./views/admin/maps/maps.component";
import { SettingsComponent } from "./views/admin/settings/settings.component";
import { TablesComponent } from "./views/admin/tables/tables.component";

// auth views
import { LoginComponent } from "./views/auth/login/login.component";
import { RegisterComponent } from "./views/auth/register/register.component";

// no layouts views
import { IndexComponent } from "./views/index/index.component";


// components for views and layouts

import { AdminNavbarComponent } from "./components/navbars/admin-navbar/admin-navbar.component";
import { AuthNavbarComponent } from "./components/navbars/auth-navbar/auth-navbar.component";

import { FooterAdminComponent } from "./components/footers/footer-admin/footer-admin.component";
import { FooterSmallComponent } from "./components/footers/footer-small/footer-small.component";
import { HeaderStatsComponent } from "./components/headers/header-stats/header-stats.component";

import { IndexDropdownComponent } from "./components/dropdowns/index-dropdown/index-dropdown.component";
import { TableDropdownComponent } from "./components/dropdowns/table-dropdown/table-dropdown.component";
import { PagesDropdownComponent } from "./components/dropdowns/pages-dropdown/pages-dropdown.component";
import { NotificationDropdownComponent } from "./components/dropdowns/notification-dropdown/notification-dropdown.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { UserDropdownComponent } from "./components/dropdowns/user-dropdown/user-dropdown.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from '@ng-select/ng-select';
import { OnBoardingComponent } from "./views/admin/on-boarding/on-boarding.component";
import { ExfightersComponent } from './views/admin/exfighters/exfighters.component';
import { RoutesComponent } from './views/admin/routes/routes.component';
import { RouteDetailsComponent } from './views/admin/route-details/route-details.component';
import { ExfightersAddComponent } from './views/admin/exfighters-add/exfighters-add.component';
import { OnBoardingAddComponent } from './views/admin/on-boarding-add/on-boarding-add.component';
import { RoutesAddComponent } from './views/admin/routes-add/routes-add.component';
import { ToastNoAnimationModule, ToastrModule } from "ngx-toastr";
import { RoutesMarkerAddComponent } from './views/admin/routes-marker-add/routes-marker-add.component';
import { RoutesMarkerEditComponent } from './views/admin/routes-marker-edit/routes-marker-edit.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NotificationsComponent } from './views/admin/notifications/notifications.component';
import { NotificationAddComponent } from './views/admin/notification-add/notification-add.component';
import { CardStatsComponent } from "./components/cards/card-stats/card-stats.component";
import { MapComponent } from './views/admin/map/map.component';
import { OnBoardingPreviewComponent } from "./views/admin/on-boarding-preview/on-boarding-preview.component";
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { AboutUsComponent } from "./views/admin/about-us/about-us.component";
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,

    IndexDropdownComponent,
    PagesDropdownComponent,
    TableDropdownComponent,
    NotificationDropdownComponent,
    UserDropdownComponent,
    SidebarComponent,
    FooterSmallComponent,
    FooterAdminComponent,

    HeaderStatsComponent,
    AuthNavbarComponent,
    AdminNavbarComponent,
    AdminComponent,
    AuthComponent,
    MapsComponent,
    SettingsComponent,
    TablesComponent,
    LoginComponent,
    RegisterComponent,
    IndexComponent,
    CardStatsComponent,
    OnBoardingComponent,
    ExfightersComponent,
    RoutesComponent,
    RouteDetailsComponent,
    ExfightersAddComponent,
    OnBoardingAddComponent,
    RoutesAddComponent,
    RoutesMarkerAddComponent,
    RoutesMarkerEditComponent,
    NotificationsComponent,
    NotificationAddComponent,
    MapComponent,
    OnBoardingPreviewComponent,
    AboutUsComponent
  ],
  imports: [BrowserModule, AppRoutingModule, HttpClientModule, FormsModule, ReactiveFormsModule, NgSelectModule, ToastNoAnimationModule.forRoot(
    {
      timeOut: 5000,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
      tapToDismiss: true,
      autoDismiss: false,
      closeButton: true,
      progressBar: true
    }
  ), CKEditorModule,FontAwesomeModule,SweetAlert2Module],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
