import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-on-boarding-preview',
  templateUrl: './on-boarding-preview.component.html',
  // styleUrls: ['./on-boarding-preview.component.css']
})
export class OnBoardingPreviewComponent implements OnInit {

  pageName = "Preview"
  public data: { [k: string]: any } = {};
  image = '';
  constructor(
    // private router: Router,
    public route: ActivatedRoute,
    public config: ConfigService,


  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((data) => {
      this.data = data
      this.image = this.config.filesUrl + data.image;

    });
  }

}
