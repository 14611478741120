import { Injectable } from '@angular/core';
import { ApiService } from "src/app/services/api-service.service";


@Injectable({
  providedIn: 'root'
})
export class SharedService {
  public dasboardData: { [k: string]: any } = {};

  constructor(
    private apiService: ApiService,
  ) {
    this.dashboard();
  }

  dashboard() {
    const api = "dashboard";
    // let params = new HttpParams();
    // params = params.append("brand_id", this.brand.toString());
    this.apiService.getData(api, {}, (result) => {
      this.dasboardData = result.data;
    });
  }
}
