import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api-service.service';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigService } from 'src/app/services/config.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-notification-add',
  templateUrl: './notification-add.component.html',
})
export class NotificationAddComponent extends ApiService implements OnInit {
  @ViewChild('form', { static: true }) form;

  constructor(
    private router: Router,
    public http: HttpClient,
    public config: ConfigService,
    public authService: AuthService,
    public route: ActivatedRoute,
    public toastr: ToastService
  ) {
    super(http, config);
  }

  pageName = 'Add Notification';
  image = '';
  fileToUpload;
  public data: { [k: string]: any } = {};
  api;

  ngOnInit(): void {
    this.route.params.subscribe((data) => {

      if (data.id) {
        setTimeout(() => {
          this.form.setValue({
            title: data.title,
            description: data.description,
            image: '',
            link: data.link,
          });
          this.image = this.config.filesUrl + data.image;
        },
          700)

        this.api = "notifications/update/" + data.id;
      } else {
        this.api = "notifications/create"

      }
    })
  }
  onSubmit(form) {
    if (form.valid && this.image) {


      form.value.image = this.fileToUpload ?? this.image;

      const formData = new FormData();

      Object.entries(form.value).forEach(
        ([key, value]) => {
          let x: any = value;
          formData.append(key, x);
        }
      );

      this.postData(this.api, formData, (result) => {
        if (result.success == 1) {
          this.toastr.success(result.message);
          this.router.navigateByUrl('/admin/notifications');
        }
      });
    }
  }

  onFileChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);


      this.fileToUpload = event.target.files[0];

      reader.onload = () => {

        this.image = reader.result as string;

      };

    }
    else {
      this.fileToUpload = null;
      this.image = ''
    }
  }

}
