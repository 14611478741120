import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "src/app/services/api-service.service";
import { AuthService } from "src/app/services/auth.service";
import { ConfigService } from "src/app/services/config.service";
import { SharedService } from "src/app/services/shared.service";

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
})
export class AdminComponent extends ApiService  implements OnInit {
  constructor(
    private api: ApiService,
    private router: Router,
    public http: HttpClient,
    public shared : SharedService,
    public config: ConfigService,
    public authService: AuthService,
  ) {
    super(http, config);
    //  this.dashboard();
  }

  ngOnInit(): void {}

  dashboard() {
    const api = "dashboard";
    // let params = new HttpParams();
    // params = params.append("brand_id", this.brand.toString());
    this.getData(api, {}, (result) => {
       this.shared.dasboardData = result.data;
    });
  }
}
