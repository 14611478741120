import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api-service.service';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigService } from 'src/app/services/config.service';
import { ToastService } from 'src/app/services/toast.service';
declare const google: any;
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-routes-marker-edit',
  templateUrl: './routes-marker-edit.component.html',
  // styleUrls: ['./routes-marker-edit.component.css']
})
export class RoutesMarkerEditComponent extends ApiService implements OnInit {
  @ViewChild('map', { static: true }) mapElement: ElementRef;
  @ViewChild('form', { static: true }) form;
  public Editor = ClassicEditor;

  constructor(
    private router: Router,
    public http: HttpClient,
    public config: ConfigService,
    public authService: AuthService,
    public route: ActivatedRoute,
    public toastr: ToastService,
    public dom: DomSanitizer
  ) {
    super(http, config);
  }
  pageName = "Add Marker"
  image = '';
  fileToUpload;
  iconToUpload;
  api;
  markers = [];
  markersOld = [];
  pin = [];
  directionsService = new google.maps.DirectionsService();
  labels = "AB";
  labelIndex = 0;
  map: any;
  lat = '33.889662';
  long = '35.494855';
   latitude : any =0.0;
  longitude = 0;
  icon = '';

  ngOnInit(): void {

    this.route.params.subscribe((data) => {
      let marker = JSON.parse(data.marker);
      let detail = JSON.parse(data.data);
      if (marker.id != null) {
        setTimeout(() => {
          this.form.setValue({
            description: marker.description,
            description_ar: marker.description_ar,
            link: marker.link,
            icon: '',
            image: '',
           
          });
          this.latitude= marker.latitude,
          this.longitude =  marker.longitude,
          this.image = this.config.filesUrl + marker.media;
          this.icon = this.config.filesUrl + marker.icon;
          let latLng = { latLng: new google.maps.LatLng(marker.latitude, marker.longitude), icon: this.icon };
          this.pin.push(latLng)
 
        },
          700)

        this.api = "routeDetails/updateMarker/" + marker.id;
      } else {
      }
      let latLng1 = { latLng: new google.maps.LatLng(detail.start_latitude, detail.start_longitude) }
      let latLng2 = { latLng: new google.maps.LatLng(detail.end_latitude, detail.end_longitude) }
      this.markersOld.push(latLng1);
      this.markersOld.push(latLng2);
    });
    setTimeout(() => {
      this.initMap();
    }, 1000)
  }
  initMap() {

    const myLatlng = new google.maps.LatLng(this.lat, this.long);
    const mapOptions = {
      zoom: 15,
      scrollwheel: false,
      center: myLatlng,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      styles: [
        {
          featureType: "administrative",
          elementType: "labels.text.fill",
          stylers: [{ color: "#444444" }],
        },

        {
          featureType: "poi",
          elementType: "all",
          stylers: [{ visibility: "off" }],
        },

        {
          featureType: "road.highway",
          elementType: "all",
          stylers: [{ visibility: "simplified" }],
        },
        {
          featureType: "road.arterial",
          elementType: "labels.icon",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "transit",
          elementType: "all",
          stylers: [{ visibility: "off" }],
        },

      ]
    };

    this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
    this.addMarker(this.pin[0])
    this.map.addListener("click", (e) => {
      if (this.markers.length < 1) {
        this.addMarker(e)
      }
    });
    this.calcRoute(this.markersOld, this.map)
  }

  addMarker(m) {
    var icon = {
      url: m.icon, // url
      scaledSize: new google.maps.Size(30, 30), // size
    };
    const marker = new google.maps.Marker({
      position: m.latLng,
      map: this.map,
      animation: google.maps.Animation.DROP,
      icon: icon
      // label:  "<i class='fa " + m.icon+ "'></i>",

    });
    this.latitude = m.latLng.lat();
    this.longitude = m.latLng.lng();
    marker.addListener("click", (e) => {

      for (var i = 0; i < this.markers.length; i++) {
        if (this.markers[i].position == e.latLng) {
          this.markers[i].setMap(null);
          this.markers.splice(i, 1);
          this.latitude = 0;
          this.longitude = 0;
          return;
        }
      }
    });
    this.markers.push(marker);
  }

  calcRoute(markers, map) {
    let directionsDisplay = null
    directionsDisplay = new google.maps.DirectionsRenderer();
    var start = new google.maps.LatLng(markers[0].latLng.lat(), markers[0].latLng.lng());
    var end = new google.maps.LatLng(markers[1].latLng.lat(), markers[1].latLng.lng());
    var request = {
      origin: start,
      destination: end,
      travelMode: google.maps.TravelMode.WALKING
    };
    this.directionsService.route(request, function (response, status) {
      if (status == google.maps.DirectionsStatus.OK) {
        directionsDisplay.setDirections(response);
        directionsDisplay.setMap(map);
      } else {
        alert("Directions Request from " + start.toUrlValue(6) + " to " + end.toUrlValue(6) + " failed: " + status);
      }
    });
  }
  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      this.fileToUpload = event.target.files[0];
      reader.onload = () => {
        this.image = reader.result as string;
      };
    }
    else {
      this.fileToUpload = null;
      this.image = ''
    }
  }
  onIconChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      this.iconToUpload = event.target.files[0];
      reader.onload = () => {
        this.icon = reader.result as string;
      };
    }
    else {
      this.iconToUpload = null;
      this.icon = ''
    }
  }
  onSubmit(form) {
    if (form.valid) {
      form.value.latitude = this.latitude;
      form.value.longitude = this.longitude;
      form.value.media = this.fileToUpload ?? this.image;
      form.value.icon = this.iconToUpload ?? this.icon;
      const formData = new FormData();
      Object.entries(form.value).forEach(
        ([key, value]) => {
          let x: any = value;
          formData.append(key, x);
        }
      );

      this.postData(this.api, formData, (result) => {
        if (result.success == 1) {
          this.toastr.success(result.message);
        }
        else {
          this.toastr.success(result.message);
        }
      });
    }
  }
}
