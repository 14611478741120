import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api-service.service';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigService } from 'src/app/services/config.service';
import { ToastService } from 'src/app/services/toast.service';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-routes-add',
  templateUrl: './routes-add.component.html',
  // styleUrls: ['./routes-add.component.css']
})
export class RoutesAddComponent extends ApiService implements OnInit {
  @ViewChild('form', { static: true }) form;
  public Editor = ClassicEditor;

  constructor(
    private router: Router,
    public http: HttpClient,
    public config: ConfigService,
    public authService: AuthService,
    public route: ActivatedRoute,
    public toastr: ToastService
  ) {
    super(http, config);
    console.log(this.route)
  }
  pageName = 'Add Route';
  image = '';
  fileToUpload;
  public data: { [k: string]: any } = {};
  exfighters = [];
  api;

  ngOnInit(): void {
this.getExfighters();
    this.route.params.subscribe((data) => {
      console.log(data);

      if (data.id) {
        setTimeout(() => {
          this.form.setValue({
            exfighter_id: data.exfighter_id,
            title: data.title,
            title_ar: data.title_ar,
            description: data.description,
            description_ar: data.description_ar,
            image: '',
          });
          this.image = this.config.filesUrl + data.image;
        },
          700)

        this.api = "routes/update/" + data.id;
      } else {
        this.api = "routes/create"

      }
    })
  }
  onSubmit(form) {
    if (form.valid && this.image) {


      form.value.image = this.fileToUpload ?? this.image;

      const formData = new FormData();

      Object.entries(form.value).forEach(
        ([key, value]) => {
          let x: any = value;
          formData.append(key, x);
        }
      );

      this.postData(this.api, formData, (result) => {
        if (result.success == 1) {
          this.toastr.success(result.message);
          this.router.navigateByUrl('/admin/routes');
        }
        else{
          this.toastr.success(result.message);

        }
      });
    }
  }

  onFileChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);


      this.fileToUpload = event.target.files[0];

      reader.onload = () => {

        this.image = reader.result as string;

      };

    }
    else {
      this.fileToUpload = null;
      this.image = ''
    }
  }

  getExfighters() {
    const api = "routes/exfighters";
    this.getData(api, {}, (result) => {
      this.exfighters = result.data;
    });
  }
}
