import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { of, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { ConfigService } from './config.service';
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  httpOptions: HttpHeaders;
  constructor(
    public http: HttpClient,
    public config: ConfigService
  ) { }

  public getData(apiUrl: any, params: any, callback: any) {
    this.createAuthorizationHeader();
    return this.http
      .get(this.config.apiURL + apiUrl, { headers: this.httpOptions, params: params })
      .pipe(
        catchError(error => {
          const errorObject = {};
          errorObject['error'] = error['name'] + " " + error['status'] + " (" + error['statusText'] + ")";
          errorObject['message'] = (error.error && error.error.message) ? error.error.message : null;
          if (error.status === 401 || error.status === 0 || error.status === 500) {
            localStorage.removeItem('access_token');
          }

          return of(errorObject);
        })
      ).subscribe(res => callback(res));
  }

  public createAuthorizationHeader() {
    const authorization = localStorage.getItem('access_token');
    if (authorization != null && authorization != undefined) {
      const options = {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          // 'Content-Type': "multipart/form-data; boundary=" + Math.random().toString().substr(2),
          'Authorization': 'Bearer ' + authorization
        }),
      };
      this.httpOptions = options.headers;
    }

  }

  public postData(apiUrl: any, body: any, callback: any) {
    this.createAuthorizationHeader();
    return this.http.post(this.config.apiURL + apiUrl, body, { headers: this.httpOptions })
      .pipe(
        catchError(error => {
          const errorObject = {};
          errorObject['error'] = error['name'] + " " + error['status'] + " (" + error['statusText'] + ")";
          if (error.status === 401 || error.status === 0 || error.status === 500) {
            localStorage.removeItem('access_token');
          }
          return of(errorObject);
        })
      ).subscribe(res => callback(res));
  }
}

