import { HttpClient } from "@angular/common/http";
import { Component, AfterViewInit, ViewChild, ElementRef } from "@angular/core";
import { Router } from "@angular/router";
import { createPopper } from "@popperjs/core";
import { ApiService } from "src/app/services/api-service.service";
import { AuthService } from "src/app/services/auth.service";
import { ConfigService } from "src/app/services/config.service";

@Component({
  selector: "app-user-dropdown",
  templateUrl: "./user-dropdown.component.html",
})
export class UserDropdownComponent extends ApiService implements AfterViewInit {
  constructor(
    private api: ApiService,
    private router: Router,
    public http: HttpClient,
    public config: ConfigService,
    public authService: AuthService,
  ) {
    super(http, config);
  }
  dropdownPopoverShow = false;
  @ViewChild("btnDropdownRef", { static: false }) btnDropdownRef: ElementRef;
  @ViewChild("popoverDropdownRef", { static: false })
  popoverDropdownRef: ElementRef;
  ngAfterViewInit() {
    createPopper(
      this.btnDropdownRef.nativeElement,
      this.popoverDropdownRef.nativeElement,
      {
        placement: "bottom-start",
      }
    );
  }
  toggleDropdown(event) {
    event.preventDefault();
    if (this.dropdownPopoverShow) {
      this.dropdownPopoverShow = false;
    } else {
      this.dropdownPopoverShow = true;
    }
  }
  logout() {
    const api = "logout";
    this.getData(api, {}, (result) => {
      if (!result.error) {
        this.authService.deleteToken();
        this.router.navigate(['/']);
      }
    });
  }
}
