import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api-service.service';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigService } from 'src/app/services/config.service';
import { ToastService } from 'src/app/services/toast.service';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-exfighters-add',
  templateUrl: './exfighters-add.component.html',
  // styleUrls: ['./exfighters-add.component.css']
})
export class ExfightersAddComponent extends ApiService implements OnInit {
  @ViewChild('form', { static: true }) form;
  public Editor = ClassicEditor;
  // public EditorAr = ClassicEditor;

  constructor(
    private router: Router,
    public http: HttpClient,
    public config: ConfigService,
    public authService: AuthService,
    public route: ActivatedRoute,
    public toastr: ToastService
  ) {
    super(http, config);
    console.log(this.route)
  }
  values = [];
  value;
  pageName = 'Add Exfighter';
  image = '';
  fileToUpload;
  public data: { [k: string]: any } = {};
  api;

  ngOnInit(): void {
    console.log(this.image)
    this.route.params.subscribe((data) => {
      console.log(data);

      if (data.id) {
        setTimeout(() => {
          this.form.setValue({
            name: data.name,
            name_ar: data.name_ar,
            description: data.description,
            description_ar: data.description_ar,
            image: '',
            link: '',
          });
          this.image = this.config.filesUrl + data.image;
          this.values = JSON.parse(data.link) ?? []
        },
          700)

        this.api = "exfighters/update/" + data.id;
      } else {
        this.api = "exfighters/create"

      }
    })
  }
  onSubmit(form) {
    if (form.valid && this.image) {


      form.value.image = this.fileToUpload ?? this.image;
      form.value.link = JSON.stringify(this.values);

      const formData = new FormData();

      Object.entries(form.value).forEach(
        ([key, value]) => {
          let x: any = value;
          formData.append(key, x);
        }
      );

      this.postData(this.api, formData, (result) => {
        if (result.success == 1) {
          this.toastr.success(result.message);
          this.router.navigateByUrl('/admin/exfighters');
        }
      });
    }
  }

  onFileChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);


      this.fileToUpload = event.target.files[0];

      reader.onload = () => {

        this.image = reader.result as string;

      };

    }
    else {
      this.fileToUpload = null;
      this.image = ''
    }
  }

  removevalue(i) {
    this.values.splice(i, 1);
  }

  addvalue() {
    this.values.push({ value: "" });
  }
  savevalue(i) {
    console.log(i)
    if (i != null && i != '' && i !=undefined) {
      this.values.push({ value: i });
      this.value = null
    }

  }
}
