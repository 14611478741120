import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
    public toastr: ToastrService
    
  ) { }

  success(message: string){
    this.toastr.success(message);
  }

  error(message: string){
    this.toastr.error(message);
  }

  info(message: string){
    this.toastr.info(message);
  }

  warning(message: string , callback:any){
  let t =  this.toastr.warning(message) ;
  t.onTap.subscribe((res)=>{
    callback(true);
  })
    }
    
  

}
