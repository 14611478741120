import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api-service.service';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigService } from 'src/app/services/config.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-exfighters',
  templateUrl: './exfighters.component.html',
  // styleUrls: ['./exfighters.component.css']
})
export class ExfightersComponent extends ApiService implements OnInit {

  constructor(
    private api: ApiService,
    private router: Router,
    public http: HttpClient,
    public config: ConfigService,
    public authService: AuthService,
    public toastr: ToastService
  ) {
    super(http, config);
  }

  page = 1;
  search = '';
  data = [];

  ngOnInit(): void {
    this.list(this.page);
  }
  list(page) {
    const api = "exfighters/list";
    let params = new HttpParams();
    params = params.append("page", page == 0 || page == 'NaN' ? 1 : page.toString());
    params = params.append("search", this.search.toString());
    this.getData(api, params, (result) => {
      this.data = result.data;
    });
  }


  delete(id) {

    let t = this.toastr.warning('Click here if you want to delete', (result) => {
      if (result) {
        const api = "exfighters/delete/" + id;
        let params = new HttpParams();
        this.postData(api, params, (result) => {
          if (result.success == 1) {
            this.list(1)
            this.toastr.success(result.message);
          }
        });
      }
    });


  }
}
