import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuardService as AuthGuard } from './services/auth-guard.service'

// layouts
import { AdminComponent } from "./layouts/admin/admin.component";
import { AuthComponent } from "./layouts/auth/auth.component";

// admin views
import { DashboardComponent } from "./views/admin/dashboard/dashboard.component";
import { MapsComponent } from "./views/admin/maps/maps.component";
import { SettingsComponent } from "./views/admin/settings/settings.component";
import { TablesComponent } from "./views/admin/tables/tables.component";

// auth views
import { LoginComponent } from "./views/auth/login/login.component";
import { RegisterComponent } from "./views/auth/register/register.component";

// no layouts views
import { IndexComponent } from "./views/index/index.component";
import { OnBoardingComponent } from "./views/admin/on-boarding/on-boarding.component";
import { ExfightersComponent } from "./views/admin/exfighters/exfighters.component";
import { ExfightersAddComponent } from "./views/admin/exfighters-add/exfighters-add.component";
import { OnBoardingAddComponent } from "./views/admin/on-boarding-add/on-boarding-add.component";
import { RoutesComponent } from "./views/admin/routes/routes.component";
import { RoutesAddComponent } from "./views/admin/routes-add/routes-add.component";
import { RouteDetailsComponent } from "./views/admin/route-details/route-details.component";
import { RoutesMarkerAddComponent } from "./views/admin/routes-marker-add/routes-marker-add.component";
import { RoutesMarkerEditComponent } from "./views/admin/routes-marker-edit/routes-marker-edit.component";
import { NotificationsComponent } from "./views/admin/notifications/notifications.component";
import { NotificationAddComponent } from "./views/admin/notification-add/notification-add.component";
import { MapComponent } from "./views/admin/map/map.component";
import { OnBoardingPreviewComponent } from "./views/admin/on-boarding-preview/on-boarding-preview.component";
import { AboutUsComponent } from "./views/admin/about-us/about-us.component";

const routes: Routes = [
  // admin views
  {
    path: "admin",
    component: AdminComponent, canActivate: [AuthGuard],
    children: [
      { path: "dashboard", component: DashboardComponent },
      { path: "settings", component: SettingsComponent },
      { path: "tables", component: TablesComponent },
      { path: "maps", component: MapsComponent },
      {
        path: "onboarding",
        children: [
          { path: "", component: OnBoardingComponent },
          { path: "add", component: OnBoardingAddComponent },
          { path: "preview", component: OnBoardingPreviewComponent },
        ],
      },
      {
        path: "exfighters",
        children: [
          { path: "", component: ExfightersComponent },
          { path: "add", component: ExfightersAddComponent },
        ]
      },
      {
        path: "routes", children: [
          { path: '', component: RoutesComponent },
          { path: "map", component: MapComponent },
          { path: "add", component: RoutesAddComponent },
          { path: "details", component: RouteDetailsComponent },
          { path: "route-marker-add", component: RoutesMarkerAddComponent },
          { path: "route-marker-edit", component: RoutesMarkerEditComponent },
        ]
      },
      {
        path: "notifications",
        children: [
          { path: '', component: NotificationsComponent },
          {
            path: "add", component: NotificationAddComponent,
          },
        ]
      },

      { path: "about-us", component: AboutUsComponent },

      { path: "", redirectTo: "dashboard", pathMatch: "full" },
    ],
  },
  // auth views
  {
    path: "auth",
    component: AuthComponent,
    children: [
      { path: "login", component: LoginComponent },
      { path: "register", component: RegisterComponent },
      { path: "", redirectTo: "login", pathMatch: "full" },
    ],
  },
  // no layout views
  // { path: "profile", component: ProfileComponent },
  // { path: "landing", component: LandingComponent },
  // { path: "", component: IndexComponent },
  { path: "", redirectTo: "auth/login", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
