import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api-service.service';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigService } from 'src/app/services/config.service';
import { ToastService } from 'src/app/services/toast.service';
declare const google: any;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  // styleUrls: ['./routes-marker-edit.component.css']
})
export class MapComponent extends ApiService implements OnInit {
  @ViewChild('map', { static: true }) mapElement: ElementRef;
  @ViewChild('form', { static: true }) form;

  constructor(
    private router: Router,
    public http: HttpClient,
    public config: ConfigService,
    public authService: AuthService,
    public route: ActivatedRoute,
    public toastr: ToastService,
    public dom: DomSanitizer
  ) {
    super(http, config);
    console.log(this.route)
  }
  pageName = "Map"
  image = '';
  fileToUpload;
  api;
  markers = [];
  markersOld = [];
  pin = [];
  directionsService = new google.maps.DirectionsService();
  labels = "AB";
  labelIndex = 0;
  map: any;
  lat = '33.889662';
  long = '35.494855';
  audio: any;

  routeDetails: { [key: string]: any };

  ngOnInit(): void {

    this.route.params.subscribe((data) => {
      console.log(data);

      data = JSON.parse(data.data);
      if (data.routes_details.length > 0) {
        this.getMap(data);

      }

    })

  }
  initMap() {

    const myLatlng = new google.maps.LatLng(this.lat, this.long);
    const mapOptions = {
      zoom: 15,
      scrollwheel: false,
      center: myLatlng,
      mapTypeId: google.maps.MapTypeId.ROADMAP,

      styles: [
        {
          featureType: "administrative",
          elementType: "labels.text.fill",
          stylers: [{ color: "#444444" }],
        },

        {
          featureType: "poi",
          elementType: "all",
          stylers: [{ visibility: "off" }],
        },

        {
          featureType: "road.highway",
          elementType: "all",
          stylers: [{ visibility: "simplified" }],
        },
        {
          featureType: "road.arterial",
          elementType: "labels.icon",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "transit",
          elementType: "all",
          stylers: [{ visibility: "off" }],
        },

      ]
    };

    this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
    for (let marker of this.pin) {

      this.addMarker(marker)


      this.map.addListener("click", (e) => {
        if (this.markers.length < 1) {

          this.addMarker(e)
        }

      });
    }
    this.calcRoute(this.markersOld, this.map)

  }

  addMarker(m) {
    console.log(m.info.link)
    let contentString =
      '<div id="content">' +
      '<div id="siteNotice">' +
      "</div>" +
      '<h1 id="firstHeading" class="firstHeading"></h1>' +
      '<div id="bodyContent">';
    if (m.info.media != null) {
      contentString += "<img width='50' src=" + this.config.filesUrl + m.info.media + "/>";


    }
    contentString +=
      "<p>" + m.info.description + "</p>" +
      "<p>" + m.info.description_ar + "</p>";
    if (m.info.link != null && m.info.link != '') {
      contentString += "<p>click <a href=" + m.info.link + " target='_blank'>click here </a> </p>";

    }
    contentString +=
      "</div>" +
      "</div>";

    const infowindow = new google.maps.InfoWindow({
      content: contentString,
    });

    var icon = {
      url: m.icon, // url
      scaledSize: new google.maps.Size(30, 30), // size
    }; const marker = new google.maps.Marker({
      position: m.latLng,
      map: this.map,
      animation: google.maps.Animation.DROP,
      icon: icon
      // label:  "<i class='fa " + m.icon+ "'></i>",

    });

    marker.addListener("click", (e) => {
      infowindow.open({
        anchor: marker,
        map: this.map,
        shouldFocus: false,
      });
      for (var i = 0; i < this.markers.length; i++) {
        if (this.markers[i].position == e.latLng) {
          // if (this.markers[i].label == 'A')
          //   this.labelIndex >= 1 ? this.labelIndex = 0 : this.labelIndex = 1
          // else (this.markers[i].label == 'B')
          // this.labelIndex >= 1 ? this.labelIndex = 1 : this.labelIndex = 0

          // this.markers[i].setMap(null);
          // this.markers.splice(i, 1);
          // console.log(this.markers)

          return;
        }
      }

    });

    this.markers.push(marker);

  }

  calcRoute(markers, map) {
    let directionsDisplay = null
    directionsDisplay = new google.maps.DirectionsRenderer();

    var start = new google.maps.LatLng(markers[0].latLng.lat(), markers[0].latLng.lng());
    var end = new google.maps.LatLng(markers[1].latLng.lat(), markers[1].latLng.lng());
    var request = {
      origin: start,
      destination: end,
      travelMode: google.maps.TravelMode.WALKING
    };
    this.directionsService.route(request, function (response, status) {
      if (status == google.maps.DirectionsStatus.OK) {
        directionsDisplay.setDirections(response);
        directionsDisplay.setMap(map);
        // directionsDisplay.setOptions({ suppressMarkers: true });
      } else {
        alert("Directions Request from " + start.toUrlValue(6) + " to " + end.toUrlValue(6) + " failed: " + status);
      }
    });
  }

  page = 1;
  search = '';
  data: { [key: string]: any };
  getMap(data) {
    // listMarkers
    const api = "routes/map/" + data.id;
    let params = new HttpParams();
    // params = params.append("page", page == 0 || page == 'NaN' ? 1 : page.toString());
    // params = params.append("search", this.search.toString());
    this.getData(api, params, (result) => {
      this.data = result.data[0];
      console.log(this.data);
      // setTimeout(() => {

      this.audio = this.config.filesUrl + this.data.routes_details[0].audio;
      let latLng1 = { latLng: new google.maps.LatLng(this.data.routes_details[0].start_latitude, data.routes_details[0].start_longitude) }
      let latLng2 = { latLng: new google.maps.LatLng(this.data.routes_details[0].end_latitude, data.routes_details[0].end_longitude) }
      this.markersOld.push(latLng1);
      this.markersOld.push(latLng2);
      // this.icon = ;
      for (let marker of this.data.routes_details[0].routes_marker) {
        let latLng = { latLng: new google.maps.LatLng(marker.latitude, marker.longitude), icon: this.config.filesUrl + marker.icon, info: marker };
        this.pin.push(latLng)
      }


      this.initMap();

      //  },
      //   700)
    });
  }

}
