import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  public isAuthenticated(): Boolean {
    let userData = localStorage.getItem('access_token')
    if (userData) {
      return true;
    }
    return false;
  }

  public setToken(token) {
    localStorage.setItem('access_token', token);
  }
  public deleteToken() {
    localStorage.removeItem('access_token');
  }
}