import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/services/api-service.service";
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigService } from "src/app/services/config.service";
import { AuthService } from "src/app/services/auth.service";
import { ToastService } from "src/app/services/toast.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
})
export class LoginComponent extends ApiService implements OnInit {
  constructor(
    private api: ApiService,
    private router: Router,
    public http: HttpClient,
    public config: ConfigService,
    public authService: AuthService,
    public toast: ToastService,
  ) {
    super(http, config);

  }
  loginData: { [k: string]: any } = {};
  ngOnInit(): void {
  }
  showError = false;

  get() {
    const api = "me";
    this.getData(api, {}, (result) => {
      console.log(result);
      this.toast.success('Welcome ' + result.name);

    });
  }
  submit(data) {
    if (data.valid) {
      this.loginData = data.form.value;
      const api = "login";
      this.postData(api, this.loginData, (result) => {
        console.log(result);
        if (!result.error) {
          this.authService.setToken(result.access_token);
          this.router.navigate(['admin/dashboard']);
          this.get();
        }
        else {
          this.toast.error('Invalid Email/Password');
        }
      });
    }
  }
}
